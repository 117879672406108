import React, { useMemo } from 'react';
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

import Table from 'components/Table';
import Stack from 'ui-kit/Stack';
import { Link, Tooltip, Typography } from 'ui-kit';
import { Pagination } from 'antd';
import { OrderCancelReasonMap, OrderStatus, OrderStatusMap } from 'api/types/order';

import OrderProductsTable from 'components/Orders/OrderProductsTable';
import { IOrder } from 'api/order_sdk';

import './styles.scss';
import {
  DATE_OF_PURCHASE_COLUMN,
  getActionColumn,
  GUEST_NAME_COLUMN,
  GUEST_PAID_COLUMN,
  YOUR_PROFIT_COLUMN,
} from './utils';

export interface IOrdersTable {
  orders: Array<IOrder>;
  currentPage: number;
  count: number;
  loading: boolean;
  setPage: (page: number) => void;
  onRefundCompleted: () => void;
  inStoreContext?: boolean;
}

const OrdersTable = ({
  orders,
  currentPage,
  count,
  loading,
  setPage,
  onRefundCompleted,
  inStoreContext = false,
}: IOrdersTable) => {
  const columns = useMemo(() => {
    if (inStoreContext) {
      return [
        {
          title: 'Sale Number',
          dataIndex: 'order_number',
          key: 'order_number',
          render: (text, order, index) => (
            <Link href={`/dashboard/store/${order.store?.id}/sales/${text}/`}>
              {count - index - 1}
            </Link>
          ),
        },
        GUEST_NAME_COLUMN,
        DATE_OF_PURCHASE_COLUMN,
        GUEST_PAID_COLUMN,
        YOUR_PROFIT_COLUMN,
        getActionColumn({ onRefundCompleted }),
      ];
    }

    return [
      {
        title: 'Sale Number',
        dataIndex: 'order_number',
        key: 'order_number',
        render: (text, order) => (
          <Link href={`/dashboard/store/${order.store?.id}/sales/${text}/`}>{text}</Link>
        ),
      },
      {
        title: 'Store',
        dataIndex: 'store.store_name',
        key: 'store_name',
        render: (text, order) => (
          <Typography variant="body1" className="store-name-cell">
            {order.store?.store_name}
          </Typography>
        ),
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, order) => (
          <Typography variant="body2">
            {OrderStatusMap[text]}{' '}
            {text === OrderStatus.CANCEL && (
              <Tooltip
                title={`The order is denied by ${OrderCancelReasonMap[order.cancel_reason]}`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            )}
          </Typography>
        ),
      },
      GUEST_NAME_COLUMN,
      DATE_OF_PURCHASE_COLUMN,
      GUEST_PAID_COLUMN,
      YOUR_PROFIT_COLUMN,
      getActionColumn({ onRefundCompleted }),
    ];
  }, [inStoreContext, count]);

  return (
    <Stack flexDirection="column" alignItems="center">
      <Table
        loading={loading}
        className="DashboardSales_table orders-table"
        dataSource={orders}
        scroll={{ x: 500 }}
        rowKey="id"
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <MinusCircleOutlined
                style={{ fontSize: '18px' }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <PlusCircleOutlined
                style={{ fontSize: '18px' }}
                onClick={(e) => onExpand(record, e)}
              />
            ),
          expandedRowRender: (order) => OrderProductsTable({ order, onRefundCompleted }),
        }}
        columns={columns}
      />
      <Pagination onChange={setPage} current={currentPage} total={count} showSizeChanger={false} />
    </Stack>
  );
};

export default OrdersTable;
