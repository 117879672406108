import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { Col, DatePicker, Row } from 'antd';

import { DATE_FILTER_OPTIONS, EDateFilter, STATUSES_OPTIONS } from 'pages/OrdersList/constants';

import { Collapse, FormControl, Select, Typography } from 'ui-kit';

import { useAuth } from 'contexts/auth/auth';
import { getStoreList } from 'api/owners';
import { OrderStatus } from 'api/types/order';

const { RangePicker } = DatePicker;

dayjs.extend(advancedFormat);

export interface IOrdersFilters {
  selectedStore: any;
  setSelectedStore: React.Dispatch<React.SetStateAction<any>>;
  selectedStatus: string | undefined;
  setSelectedStatus: React.Dispatch<React.SetStateAction<OrderStatus | undefined>>;
  selectedDateFilter: EDateFilter;
  setSelectedDateFilter: React.Dispatch<React.SetStateAction<EDateFilter>>;
  startDate: Date | undefined;
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  endDate: Date | undefined;
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
}

const OrdersFilters = ({
  selectedStore,
  setSelectedStore,
  selectedStatus,
  setSelectedStatus,
  selectedDateFilter,
  setSelectedDateFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setCurrentPage,
  setOffset,
}: IOrdersFilters) => {
  const { id: ownerId } = useAuth();

  const {
    data: { stores },
  } = getStoreList(ownerId);

  const storesOptions = useMemo(
    () =>
      stores?.map((store) => ({
        label: store.storeName,
        value: store.id,
      })) || [],
    [stores],
  );

  const handleChangeStore = useCallback(
    (_, option) => {
      setSelectedStore(option);
      setOffset(0);
      setCurrentPage(1);
    },
    [setOffset, setCurrentPage],
  );

  const handleChangeStatus = useCallback(
    (_, option) => {
      setSelectedStatus(option?.value);
      setOffset(0);
      setCurrentPage(1);
    },
    [setOffset, setCurrentPage],
  );

  const handleChangeDateFilter = useCallback(
    (_, option) => {
      setSelectedDateFilter(option?.value);
      setOffset(0);
      setCurrentPage(1);
    },
    [setOffset, setCurrentPage],
  );

  const onChangeRange = useCallback(
    (arr) => {
      if (_.isNull(arr)) {
        setStartDate(undefined);
        setEndDate(undefined);
        setOffset(0);
        setCurrentPage(1);
        return;
      }

      const start = new Date(arr[0]);
      const end = new Date(arr[1]);
      setStartDate(start);
      setEndDate(end);
      setOffset(0);
      setCurrentPage(1);
    },
    [setOffset, setCurrentPage],
  );

  const dateFilterLabel = useMemo(
    () => _.find(DATE_FILTER_OPTIONS, { value: selectedDateFilter })?.label,
    [selectedDateFilter],
  );

  return (
    <Collapse header={() => <Typography variant="h6">Filters</Typography>} initialCollpase={false}>
      <div className="filters-box">
        <Row gutter={[36, 12]}>
          <Col md={6} span={12}>
            <FormControl label="Store name" fullWidth>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => {
                  const inputValue = input.toLowerCase();
                  return option.children.toLowerCase().indexOf(inputValue) >= 0;
                }}
                onChange={handleChangeStore}
                placeholder="Select Store"
                value={selectedStore?.value}
                options={storesOptions}
              />
            </FormControl>
          </Col>
          <Col md={6} span={12}>
            <FormControl label="Status" fullWidth>
              <Select
                allowClear
                onChange={handleChangeStatus}
                placeholder="Select Status"
                value={selectedStatus}
                options={STATUSES_OPTIONS}
              />
            </FormControl>
          </Col>
          <Col md={6} span={12}>
            <FormControl label="Select date type" fullWidth>
              <Select
                onChange={handleChangeDateFilter}
                placeholder="Select"
                value={selectedDateFilter}
                options={DATE_FILTER_OPTIONS}
              />
            </FormControl>
          </Col>
          <Col md={6} span={12}>
            <FormControl label={dateFilterLabel} fullWidth>
              <RangePicker
                allowClear
                size="large"
                value={[moment(startDate), moment(endDate)]}
                onChange={onChangeRange}
              />
            </FormControl>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default OrdersFilters;
