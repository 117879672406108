import { OrderStatus, OrderStatusMap } from 'api/types/order';

export const STATUSES_OPTIONS = [
  {
    label: OrderStatusMap[OrderStatus.COMPLETED],
    value: OrderStatus.COMPLETED,
  },
  {
    label: OrderStatusMap[OrderStatus.HOLD],
    value: OrderStatus.HOLD,
  },
  {
    label: OrderStatusMap[OrderStatus.CANCEL],
    value: OrderStatus.CANCEL,
  },
  {
    label: OrderStatusMap[OrderStatus.REFUNDED],
    value: OrderStatus.REFUNDED,
  },
];

export enum EDateFilter {
  DATE_OF_SERVICE = 'date_of_service',
  DATE_CREATED = 'date_created',
}

export const DATE_FILTER_OPTIONS = [
  {
    label: 'Date of Purchase',
    value: EDateFilter.DATE_CREATED,
  },
  {
    label: 'Date of Service',
    value: EDateFilter.DATE_OF_SERVICE,
  },
];
