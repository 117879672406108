export const EXPORT_ORDERS_COLUMNS = [
  'Guest name',
  'Date',
  'Amount',
  'Your Profit',
  'Order Number',
  'Store Name',
];

export const EXPORT_ORDER_PRODUCTS_COLUMNS = [
  'Guest Name',
  'Product Name',
  'Date created',
  'Date of service',
  'Amount',
  'Your Profit (Your profit may not be exact due to process timing)',
  'Order Number',
  'Store Name',
];
